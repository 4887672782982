import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker REST API with your WQHD Camera",
  "path": "/Frequently_Asked_Question/WQHD_with_ioBroker_REST_API/",
  "dateChanged": "2023-01-23",
  "author": "Mike Polinowski",
  "excerpt": "How can I use the the ioBroker Simple RESTful API Adapter as the Alarmserver of my WQHD 2k+ Camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker REST API with your WQHD Camera' dateChanged='2023-01-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='How can I use the the ioBroker Simple RESTful API Adapter as the Alarmserver of my WQHD 2k+ Camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_with_ioBroker_REST_API/' locationFR='/fr/Frequently_Asked_Question/WQHD_with_ioBroker_REST_API/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "iobroker-rest-api-with-your-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-rest-api-with-your-wqhd-camera",
        "aria-label": "iobroker rest api with your wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker REST API with your WQHD Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How can I use the the ioBroker `}<a parentName="p" {...{
        "href": "https://github.com/ioBroker/ioBroker.simple-api"
      }}>{`Simple RESTful API Adapter`}</a>{` as the Alarmserver of my WQHD 2k+ Camera?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by running an instance of the adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d4360e041973b8f32b752e86f7bab273/91608/Using_ioBroker_with_your_WQHD_Camera_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA9klEQVQY022LzU6DQBSFeQGNkFpoAS0IM3Mvw9/8IAOJo4aVtY9gX8CNC9258skNjbGJ9ctdnJtzPifI6yBYLS8XwzBYez9NU9/3UkpjTKeVFO3hhJJSa620VkoJJba7rbXWoWLMChFvbpp2pmkaAKCUGmMG+1h3Q3s7ivGh1CPyGgAppVVTyU5yzp2qtzkX0XUKiMWR+el2e/vyfrf/eHr9fH77Apw5dD/BISSPotBzXcbYb4GIwCADygrOsMigDGkLjCEeB4jolIRchZHreadymqeM0XC9vjg/Wy0XeIJDkk0YBP/IAIRQXvA4jn3fT5IUAP7I3/vbPrM0BZq5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4360e041973b8f32b752e86f7bab273/e4706/Using_ioBroker_with_your_WQHD_Camera_01.avif 230w", "/en/static/d4360e041973b8f32b752e86f7bab273/d1af7/Using_ioBroker_with_your_WQHD_Camera_01.avif 460w", "/en/static/d4360e041973b8f32b752e86f7bab273/7f308/Using_ioBroker_with_your_WQHD_Camera_01.avif 920w", "/en/static/d4360e041973b8f32b752e86f7bab273/f2659/Using_ioBroker_with_your_WQHD_Camera_01.avif 1251w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d4360e041973b8f32b752e86f7bab273/a0b58/Using_ioBroker_with_your_WQHD_Camera_01.webp 230w", "/en/static/d4360e041973b8f32b752e86f7bab273/bc10c/Using_ioBroker_with_your_WQHD_Camera_01.webp 460w", "/en/static/d4360e041973b8f32b752e86f7bab273/966d8/Using_ioBroker_with_your_WQHD_Camera_01.webp 920w", "/en/static/d4360e041973b8f32b752e86f7bab273/e4396/Using_ioBroker_with_your_WQHD_Camera_01.webp 1251w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4360e041973b8f32b752e86f7bab273/81c8e/Using_ioBroker_with_your_WQHD_Camera_01.png 230w", "/en/static/d4360e041973b8f32b752e86f7bab273/08a84/Using_ioBroker_with_your_WQHD_Camera_01.png 460w", "/en/static/d4360e041973b8f32b752e86f7bab273/c0255/Using_ioBroker_with_your_WQHD_Camera_01.png 920w", "/en/static/d4360e041973b8f32b752e86f7bab273/91608/Using_ioBroker_with_your_WQHD_Camera_01.png 1251w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d4360e041973b8f32b752e86f7bab273/c0255/Using_ioBroker_with_your_WQHD_Camera_01.png",
              "alt": "ioBroker REST API with your WQHD Camera",
              "title": "ioBroker REST API with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure the adapter to use a interface on the same network as your camera. You can also adjust the port the service is working on - the default value is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8087`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8c310446f065a0c12b8624865a72184c/f09ab/Using_ioBroker_with_your_WQHD_Camera_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABOklEQVQoz43OP0vDQBgG8Bu6dBCaKNKk/6AUL8l7yfXucrnLXbm0FGqGQsVNB6kdnXSpg5/BwW8sRiiUFvG3vMv7PDwoiPV10OsGYVVVq9VqvV4758qyNMaU5xhjlCo2m7vlcolGw0F41em0W0BIaUxVVc65+XxurRWF0KXORS6Occ6ttbvdDtWvX+7lk9y/kbykhU15ntEso5TAeYQQjHFd1/v9HrntR/74fnO71YWySikhOCEkSaL4BwA0N06OYYwnkwmKB93Qv+i0W9soekrJA8bPAIsoigihWSalbKoSrXWapoeW3xUoHo/7vZ7nX2ohFrMZBaAAKcDh47A2OYEwzcP+0Pc8ypjSmnFujGGMQZP/G8opC8LQ973plBVFwTmXUlJKk39A0Bt1w8D3PCmlUiptnB15Gv4GtCZkY4L+13wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c310446f065a0c12b8624865a72184c/e4706/Using_ioBroker_with_your_WQHD_Camera_02.avif 230w", "/en/static/8c310446f065a0c12b8624865a72184c/d1af7/Using_ioBroker_with_your_WQHD_Camera_02.avif 460w", "/en/static/8c310446f065a0c12b8624865a72184c/7f308/Using_ioBroker_with_your_WQHD_Camera_02.avif 920w", "/en/static/8c310446f065a0c12b8624865a72184c/09946/Using_ioBroker_with_your_WQHD_Camera_02.avif 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8c310446f065a0c12b8624865a72184c/a0b58/Using_ioBroker_with_your_WQHD_Camera_02.webp 230w", "/en/static/8c310446f065a0c12b8624865a72184c/bc10c/Using_ioBroker_with_your_WQHD_Camera_02.webp 460w", "/en/static/8c310446f065a0c12b8624865a72184c/966d8/Using_ioBroker_with_your_WQHD_Camera_02.webp 920w", "/en/static/8c310446f065a0c12b8624865a72184c/e19c7/Using_ioBroker_with_your_WQHD_Camera_02.webp 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c310446f065a0c12b8624865a72184c/81c8e/Using_ioBroker_with_your_WQHD_Camera_02.png 230w", "/en/static/8c310446f065a0c12b8624865a72184c/08a84/Using_ioBroker_with_your_WQHD_Camera_02.png 460w", "/en/static/8c310446f065a0c12b8624865a72184c/c0255/Using_ioBroker_with_your_WQHD_Camera_02.png 920w", "/en/static/8c310446f065a0c12b8624865a72184c/f09ab/Using_ioBroker_with_your_WQHD_Camera_02.png 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8c310446f065a0c12b8624865a72184c/c0255/Using_ioBroker_with_your_WQHD_Camera_02.png",
              "alt": "ioBroker REST API with your WQHD Camera",
              "title": "ioBroker REST API with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now switch to the object tree and and create a folder for cameras with subfolders for each camera inside the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userdata`}</code>{` directory:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/afcb55d7b0e15ed20a88303e018a9926/fbf76/Using_ioBroker_with_your_WQHD_Camera_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABzUlEQVQoz23L3W+aUBgGcC6Kw8jHgQOIgmygBdOWKiCfgkqzJbvpxZK1XXao2tKD8WIzvdj1brrs3146m65b9uR38bzJ+xCM1G3RDE3Tuq73zb5uvtENwxoeqz1d0zRV01T1P2x7aJomASWJ4ziKoqAodBQZiiKU5dcidHgOSlCCPBQAz3OCwO0LFEBbFqEARFkkunpfUFQBQH/gRMeBZ419e1y4UTgce7br2Y+na41+F9e1Rs+yQ4c4nH/UZ5eG/35ue/nQTy03NJziaJJZbtw/jcx/TQfj1HLzo+D8JCAUuc3zQqPZMgbWu7fFLE8X83yxWMzz6Xw2TeMwiYK9OJxkaTzoG4BlZEloK20C8jzLMOTBwak72e12db3BuMYY47rGuF6vb1ar9d5yucK4jqIYANDpdjVNI6SOxrAcSTackXdXVQihzy+CnlNeI1RWVRUEAQBAUZTHsSa3WZYlycbJyKs32xKV+1/0d8pPF1eXFwiVvu//GYMWTdM02Wg4Yx9vtqtbvFxXy5u7F6pltbm6f9h++/7w42dxdsYwzNP4VZNqNimKapoDK8+yOI7TNCmKIkniJ3GUZJnz4es5qu93X8Iw4jhO6XR6vd4vPvx26MTYeAEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/afcb55d7b0e15ed20a88303e018a9926/e4706/Using_ioBroker_with_your_WQHD_Camera_03.avif 230w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/d1af7/Using_ioBroker_with_your_WQHD_Camera_03.avif 460w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/7f308/Using_ioBroker_with_your_WQHD_Camera_03.avif 920w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/ceb0a/Using_ioBroker_with_your_WQHD_Camera_03.avif 1252w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/afcb55d7b0e15ed20a88303e018a9926/a0b58/Using_ioBroker_with_your_WQHD_Camera_03.webp 230w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/bc10c/Using_ioBroker_with_your_WQHD_Camera_03.webp 460w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/966d8/Using_ioBroker_with_your_WQHD_Camera_03.webp 920w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/5733b/Using_ioBroker_with_your_WQHD_Camera_03.webp 1252w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/afcb55d7b0e15ed20a88303e018a9926/81c8e/Using_ioBroker_with_your_WQHD_Camera_03.png 230w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/08a84/Using_ioBroker_with_your_WQHD_Camera_03.png 460w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/c0255/Using_ioBroker_with_your_WQHD_Camera_03.png 920w", "/en/static/afcb55d7b0e15ed20a88303e018a9926/fbf76/Using_ioBroker_with_your_WQHD_Camera_03.png 1252w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/afcb55d7b0e15ed20a88303e018a9926/c0255/Using_ioBroker_with_your_WQHD_Camera_03.png",
              "alt": "ioBroker REST API with your WQHD Camera",
              "title": "ioBroker REST API with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`At the end of this folder structure we now need to add a datapoint that we will be able to toggle by calling the REST endpoint:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/f09ab/Using_ioBroker_with_your_WQHD_Camera_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB3ElEQVQoz3XLXWvTYBjG8cigWVs1TfK853ny6sYQLSjqgbjJTOo6285u1dI0b12TbF0neFAQejg/gJ9ZGkEZ0j83N1wHP6n5SKnXdznnruu6nsss+vRZ2/U8viXGmOM4B3t7nDFJ1UCj0UQQCsEpM4jBuTAhxqQK/hchhDHGDQNCKLUeP5R3JACRKQSlFCFs25ZhGJRSQggllGCMEaoWwQgxxjBGGOMNtt+c0hcB5C4iDECktFRuWo73pKVqqg5UTVd1oOlAA1CvDiIEEeamhTGRePudvv9aPH/bv5gMzr987J2d9D4PLr52+8PuYNgdnFd/6J/0jjunR0HXeRWIl8HB4Zmx35bU5u7OA0lYTl7ky+XN4vr6ZrG4Kssiz8uiKIq8LPKiyGdZOsuyaRgK0waImLZDKJMUTa/VasJyZrPLLMuiKIrjOIri6b2iaBolSTIejw3GgK4zxgAAUktVN9j2yrKcz+dxHCdbStN0MpkYhqFXGGMsKYoiy7KwvaIotuA0icI4HIdhOBqN7uFGo7HBjnc5n5dlmaZZWpX9LU2Sq2/p7Y/VarVcLjnn/3C9XpdlmQn7g+8HQeD7/qdev98fBNUMOp3O8dHh9Pv7218/7+7W6zWl9A9GCP0Ga8CFyxr8YJYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/e4706/Using_ioBroker_with_your_WQHD_Camera_04.avif 230w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/d1af7/Using_ioBroker_with_your_WQHD_Camera_04.avif 460w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/7f308/Using_ioBroker_with_your_WQHD_Camera_04.avif 920w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/09946/Using_ioBroker_with_your_WQHD_Camera_04.avif 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/a0b58/Using_ioBroker_with_your_WQHD_Camera_04.webp 230w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/bc10c/Using_ioBroker_with_your_WQHD_Camera_04.webp 460w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/966d8/Using_ioBroker_with_your_WQHD_Camera_04.webp 920w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/e19c7/Using_ioBroker_with_your_WQHD_Camera_04.webp 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/81c8e/Using_ioBroker_with_your_WQHD_Camera_04.png 230w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/08a84/Using_ioBroker_with_your_WQHD_Camera_04.png 460w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/c0255/Using_ioBroker_with_your_WQHD_Camera_04.png 920w", "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/f09ab/Using_ioBroker_with_your_WQHD_Camera_04.png 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8f87fb1645e84c58bfa8a20d26d3b1e8/c0255/Using_ioBroker_with_your_WQHD_Camera_04.png",
              "alt": "ioBroker REST API with your WQHD Camera",
              "title": "ioBroker REST API with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I created my datapoint `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Alarmserver`}</code>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0_userdata/0/Cameras/INSTAR120`}</code>{` and my ioBroker instance is running on a server with the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.112`}</code>{` on the default port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8087`}</code>{`. I can test this REST endpoint from my browser or a tool like `}<a parentName="p" {...{
        "href": "https://www.postman.com/downloads/"
      }}>{`Postman`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.112:8087/set/0_userdata.0.Cameras.INSTAR120.Alarmserver?value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/adc07bb064610bb66eba19e9dcabce79/d93d9/Using_ioBroker_with_your_WQHD_Camera_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACEElEQVQoz0XI204TURgF4HkHhQSKnZk9e2b28d+HdqZTaAsFRaiC0BQSOQSD0RgDQguUg5zEBglee2N8AS98R4MgrHwXay1nMC709/XnckPVWnW8Pl4azSqjtcbsfJIkaZomSVIoFIr/cl+sLUxPN+r1ukO4yuUG+x4/0kplWcbA2qQESnMhjTEAwDmnlDLGhBCEECllkiRCcGOMU6xO+BQGXFwoVbI05Yxba2dnZpRSCgCkVEpJIUGC0VoKqUCBUlqbJEmdxtnvsZM/Zu2r0MXSSEVbg6Nw6kUjLWc4CiNKHpA4ooSQmMRhKSnWKsOOBDvohQNYjqwcby91NtYOlptb75f2Pix33yx21hZ3Vlrbq632aqu9stBZn9+YW2jb9V727rL29tTZ2fi4+Wmr3dk9PO1d7B6c7xxcdI9u7B1eHZ9fn3zp7X++e7pHve7hydHZ/tnlz+/XP66+OUyCtpYDgBLGKqVEzEhAScgoBUkBIs4CEt9jIBSI3ad6c8I6NAjKjGWMlTmvKVUDqEo5zHlGKbiuyOch74L7IGNMh7GMZIhjp4Zxk9GpOG6CmgN4JcU8wK2mUi8peYbQZBBMInRTcPjcGxpLZ8Zf/yqLqiOiqMKZCUPlIx0ghW4YjDVCGiHpebceputKzzMRpxg7PkJSaxehvO/feuJ5PsZeEOR93/0vCCMcRXcToYjEAcZ/AXM4lCjl8yzZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/adc07bb064610bb66eba19e9dcabce79/e4706/Using_ioBroker_with_your_WQHD_Camera_05.avif 230w", "/en/static/adc07bb064610bb66eba19e9dcabce79/d1af7/Using_ioBroker_with_your_WQHD_Camera_05.avif 460w", "/en/static/adc07bb064610bb66eba19e9dcabce79/7f308/Using_ioBroker_with_your_WQHD_Camera_05.avif 920w", "/en/static/adc07bb064610bb66eba19e9dcabce79/95942/Using_ioBroker_with_your_WQHD_Camera_05.avif 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/adc07bb064610bb66eba19e9dcabce79/a0b58/Using_ioBroker_with_your_WQHD_Camera_05.webp 230w", "/en/static/adc07bb064610bb66eba19e9dcabce79/bc10c/Using_ioBroker_with_your_WQHD_Camera_05.webp 460w", "/en/static/adc07bb064610bb66eba19e9dcabce79/966d8/Using_ioBroker_with_your_WQHD_Camera_05.webp 920w", "/en/static/adc07bb064610bb66eba19e9dcabce79/972ad/Using_ioBroker_with_your_WQHD_Camera_05.webp 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/adc07bb064610bb66eba19e9dcabce79/81c8e/Using_ioBroker_with_your_WQHD_Camera_05.png 230w", "/en/static/adc07bb064610bb66eba19e9dcabce79/08a84/Using_ioBroker_with_your_WQHD_Camera_05.png 460w", "/en/static/adc07bb064610bb66eba19e9dcabce79/c0255/Using_ioBroker_with_your_WQHD_Camera_05.png 920w", "/en/static/adc07bb064610bb66eba19e9dcabce79/d93d9/Using_ioBroker_with_your_WQHD_Camera_05.png 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/adc07bb064610bb66eba19e9dcabce79/c0255/Using_ioBroker_with_your_WQHD_Camera_05.png",
              "alt": "ioBroker REST API with your WQHD Camera",
              "title": "ioBroker REST API with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Sending a GET request successfully toggled the endpoint and it now has a value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{`.`}</p>
    <p>{`To configure your camera by opening the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`Alarmserver Menu`}</a>{` and add the URL you just called above:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/04ce10daad24975e4baa93d355926b22/d93d9/Using_ioBroker_with_your_WQHD_Camera_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABjElEQVQoz23Rz26bMBzAcR6gBBPIoSibFAkOZsoLZCVRA6QJAxb/wcRQh0wj3XHXNcfutOveor1tTzjZzqJt2le+fvzzH2M6nb5RhWEYBEH26dvu6Sc6PZenl/JRrdNL8eWZf/2x/fx9/Oo1hHA2m43H48lkYoRhqKXEvr9M73bdQy16Jnq27+v9kYkjE/2ue7jbvHMdZzQaua4LAHAcx9BjIYRnvIxb0R0+HnkrSFWTqkakohWjdZOuM2coAwAMVH9h3/fjOG6ahlKKMSKESFYxQilrD2nBgGVqaZqmZVmGPvAFJ0nStq3ECGGMMbtH9X7L7gk/JOvcGpwzTbnLvziOY845UWVZtlgsoih6e3Mzn0fzKLJt+zcfSHx56stkzjnGmBCS53mapitVlm1ul7dAZamGw6EBIfzzzhozJu+ptyCEIIS6w4ciL/RAAICr+g8WQtR1LT0hWLV9v+27fY6yqytTe9u25bE1O39VEKxWK940FaUE47IsN6r1ekPzLCmW3rXnedc6z/N+AYswhrKHgKxIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04ce10daad24975e4baa93d355926b22/e4706/Using_ioBroker_with_your_WQHD_Camera_06.avif 230w", "/en/static/04ce10daad24975e4baa93d355926b22/d1af7/Using_ioBroker_with_your_WQHD_Camera_06.avif 460w", "/en/static/04ce10daad24975e4baa93d355926b22/7f308/Using_ioBroker_with_your_WQHD_Camera_06.avif 920w", "/en/static/04ce10daad24975e4baa93d355926b22/95942/Using_ioBroker_with_your_WQHD_Camera_06.avif 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/04ce10daad24975e4baa93d355926b22/a0b58/Using_ioBroker_with_your_WQHD_Camera_06.webp 230w", "/en/static/04ce10daad24975e4baa93d355926b22/bc10c/Using_ioBroker_with_your_WQHD_Camera_06.webp 460w", "/en/static/04ce10daad24975e4baa93d355926b22/966d8/Using_ioBroker_with_your_WQHD_Camera_06.webp 920w", "/en/static/04ce10daad24975e4baa93d355926b22/972ad/Using_ioBroker_with_your_WQHD_Camera_06.webp 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04ce10daad24975e4baa93d355926b22/81c8e/Using_ioBroker_with_your_WQHD_Camera_06.png 230w", "/en/static/04ce10daad24975e4baa93d355926b22/08a84/Using_ioBroker_with_your_WQHD_Camera_06.png 460w", "/en/static/04ce10daad24975e4baa93d355926b22/c0255/Using_ioBroker_with_your_WQHD_Camera_06.png 920w", "/en/static/04ce10daad24975e4baa93d355926b22/d93d9/Using_ioBroker_with_your_WQHD_Camera_06.png 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/04ce10daad24975e4baa93d355926b22/c0255/Using_ioBroker_with_your_WQHD_Camera_06.png",
              "alt": "ioBroker REST API with your WQHD Camera",
              "title": "ioBroker REST API with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save your settings and click on the `}<strong parentName="p">{`Test`}</strong>{` button to send a request:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/91608/Using_ioBroker_with_your_WQHD_Camera_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABA0lEQVQY002HS0rDQABAc4dSmqxqsJlP5j/NTCYzFMaCNTbioktBzUpR6QFEaYuIUNx4DFeeURAXfbzFe0kG9SjN0jQNIczj3J94N/MXl6vaOWNM9c9hGKVU13UxxgSWbHyUDwcDxmjwvuRaVrXUU0KZUopzXpYlQghjTCnBCFFKrbWMMWOqRJomL8AwzZQN3nvKmFRquey0nnIhuBBCSsY550IqRf5eCME4N8Ym7eZ7vv2p7r+ItM41lZYATNrFafAOFhOMAIYAI4AQIBC44hhDAEGhlZwFnwgXc6JHY+hvX8/Wn+3jR32zWTztz9d732+bfnfo6uo5Xr/U/S7evbcPb7/320RJo55LEgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/e4706/Using_ioBroker_with_your_WQHD_Camera_07.avif 230w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/d1af7/Using_ioBroker_with_your_WQHD_Camera_07.avif 460w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/7f308/Using_ioBroker_with_your_WQHD_Camera_07.avif 920w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/f2659/Using_ioBroker_with_your_WQHD_Camera_07.avif 1251w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/a0b58/Using_ioBroker_with_your_WQHD_Camera_07.webp 230w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/bc10c/Using_ioBroker_with_your_WQHD_Camera_07.webp 460w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/966d8/Using_ioBroker_with_your_WQHD_Camera_07.webp 920w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/e4396/Using_ioBroker_with_your_WQHD_Camera_07.webp 1251w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/81c8e/Using_ioBroker_with_your_WQHD_Camera_07.png 230w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/08a84/Using_ioBroker_with_your_WQHD_Camera_07.png 460w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/c0255/Using_ioBroker_with_your_WQHD_Camera_07.png 920w", "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/91608/Using_ioBroker_with_your_WQHD_Camera_07.png 1251w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0482fe4fc1d697e486c3120fa9fa6c0d/c0255/Using_ioBroker_with_your_WQHD_Camera_07.png",
              "alt": "ioBroker REST API with your WQHD Camera",
              "title": "ioBroker REST API with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The alarmserver successfully changed the value in ioBroker. All you need to do now is to select a script engine - Blockly or Node-RED - and write a script to react to this change.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      